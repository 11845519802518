<template>
    <div class="about-us">
        <side-bar />
        <nav-bar-auth v-if="!isLoggedIn" />
        <video-intro-modal />
        <main class="container">
            <!-- <about-us-hero /> -->
            <what-is-memod />
            <hr>
            <key-features />
            <hr>
            <div class="who-we-are">
                <h1 class="h0">
                    Who we are
                </h1>
                <p>Like you, we on the Memo’d team want to constantly improve and grow. Check out four of our profiles to learn more about our interests and journeys</p>
                <div class="row">
                    <div v-for="(collaborator, index) in collaborators" :key="index" class="col-12 col-lg">
                        <div class="collaborator-card">
                            <figure>
                                <img :src="collaborator.picture" :alt="collaborator.name">
                            </figure>
                            <p class="text-truncate">
                                {{ collaborator.name }}
                            </p>
                            <a class="btn btn-primary" :href="collaborator.link" target="_blank">View Profile</a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <more-features :feature="behindMemod" />
            <hr>
            <more-features :feature="sharingKnowledge" />
            <hr>
            <div class="memos-on-memod">
                <h1 class="h0">
                    Memos on Memo'd
                </h1>
                <p>Learn more about the origins of the platform, the big ideas behind its inception, and everything Memo'd has to<br class="d-none d-lg-block">offer knowledge-seekers like you.</p>
                <div class="row">
                    <div v-for="(memo, index) in memos" :key="index" class="col-12 col-lg-6 col-xl-auto">
                        <memod-card
                            :key="memo.id"
                            :memod="memo"
                        />
                    </div>
                </div>
            </div>
        </main>
        <apply-founders />
        <landing-footer />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "AboutUs",
    components: {
        VideoIntroModal: () => import(/* webpackChunkName: "video-intro-hero" */ "../founders-landing/hero/video-intro-modal.vue"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        WhatIsMemod: () => import(/* webpackChunkName: "what-is-memod" */ "../landing/what-is-memod"),
        KeyFeatures: () => import(/* webpackChunkName: "key-features" */ "../founders-landing/key-features"),
        MoreFeatures: () => import(/* webpackChunkName: "more-features" */ "../founders-landing/more-features/feature"),
        ApplyFounders: () => import(/* webpackChunkName: "apply-founders" */ "../founders-landing/apply-founders"),
        LandingFooter: () => import(/* webpackChunkName: "landing-footer" */ "@v/landing/footer"),
        MemodCard: () => import(/* webpackChunkName: "memod-card" */ "@/components/molecules/memod-card/"),
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth")
    },
    data() {
        return {
            collaborators: [
                {
                    picture: require("@assets/img/founders-landing/participants/stanley.jpg"),
                    name: "Stanley Yuan",
                    link: "/memodian/memos"
                },
                {
                    picture: require("@assets/img/founders-landing/participants/karin.jpeg"),
                    name: "Karin Richey",
                    link: "/KarinRichey/memos"
                },
                {
                    picture: require("@assets/img/founders-landing/participants/tom.jpeg"),
                    name: "Tom Butler-Bowdon",
                    link: "/TomButlerBowdon/memos"
                },
                {
                    picture: require("@assets/img/founders-landing/participants/cian.png"),
                    name: "Cian Traynor",
                    link: "/Cian/memos"
                }
            ],
            memos: [
                {
                    "id": 1324,
                    "files": [
                        {
                            "field_name": "cover-image",
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/Qjm1UE.jpg",
                            "attributes": {
                                "height": "951",
                                "orientation": "portrait",
                                "unique_name": "images/Qjm1UE.jpg",
                                "width": "634"
                            }
                        }
                    ],
                    "title": "A Memo on Memo’d",
                    "slug": "a-memo-on-memo-d",
                    "is_published": 1,
                    "writer": {
                        "displayname": "MemodTeam",
                        "photo": {
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/06EPCe.jpg"
                        }
                    },
                    "type": {
                        "id": "1",
                        "name": "Single"
                    }
                },
                {
                    "id": 1330,
                    "files": [
                        {
                            "field_name": "cover-image",
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/5cRC8b.jpg",
                            "attributes": {
                                "height": "951",
                                "orientation": "portrait",
                                "unique_name": "images/5cRC8b.jpg",
                                "width": "634"
                            }
                        }
                    ],
                    "title": "The origins of Memo'd",
                    "slug": "the-origins-of-memo-d",
                    "is_published": 1,
                    "writer": {
                        "displayname": "MemodTeam",
                        "photo": {
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/06EPCe.jpg"
                        }
                    },
                    "type": {
                        "id": "1",
                        "name": "Single"
                    }
                },
                {
                    "id": 1325,
                    "files": [
                        {
                            "field_name": "cover-image",
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/T0RNrw.jpg",
                            "attributes": {
                                "height": "951",
                                "orientation": "portrait",
                                "unique_name": "images/T0RNrw.jpg",
                                "width": "634"
                            }
                        }
                    ],
                    "title": "Memo'd: Our Story",
                    "slug": "memo-d-our-story",
                    "is_published": 1,
                    "writer": {
                        "displayname": "MemodTeam",
                        "photo": {
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/06EPCe.jpg"
                        }
                    },
                    "type": {
                        "id": "1",
                        "name": "Single"
                    }
                },
                {
                    "id": 1323,
                    "files": [
                        {
                            "field_name": "cover-image",
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/orBqHc.jpg",
                            "attributes": {
                                "height": "951",
                                "orientation": "portrait",
                                "unique_name": "images/orBqHc.jpg",
                                "width": "634"
                            }
                        }
                    ],
                    "title": "Memo’d Manifesto",
                    "slug": "memo-d-manifesto",
                    "is_published": 1,
                    "writer": {
                        "displayname": "MemodTeam",
                        "photo": {
                            "url": "https://memod-public.s3.us-east-1.amazonaws.com/images/06EPCe.jpg"
                        }
                    },
                    "type": {
                        "id": "1",
                        "name": "Single"
                    }
                }
            ],
            behindMemod: {
                title: "Learn more about the thinking behind Memo’d ",
                isExpanded: true,
                descriptionBullets: [
                    {
                        hideOnMobile: false,
                        title: "New era.",
                        bullet: "The internet created an explosion in information, but we’re at the limits of what our minds can organize and absorb. We need a better system for gaining, storing, retaining and sharing knowledge."
                    },
                    {
                        hideOnMobile: false,
                        title: "Insight hunger.",
                        bullet: "There’s a permanent demand for insights that can help us succeed in life and work, but we need to separate the valuable from the noise."
                    },
                    {
                        hideOnMobile: false,
                        title: "Shift to short form.",
                        bullet: "There’s a shift to shorter form content. The nature of writing itself is changing. We’re seeing a transition from the paragraph to the “information block” or text cell."
                    },
                    {
                        hideOnMobile: false,
                        link: {
                            href: "https://memod-public.s3.amazonaws.com/Memo%27d+White+Paper+2020.pdf",
                            text: "Get The Memo? Crowdsourced wisdom and the shareable notes revolution"
                        },
                        bullet: "For more details on the thinking behind Memo'd, download our White Paper:"
                    }
                ],
                relativePathImages: {
                    web: {
                        x1: "behind-memod.jpg",
                        x2: "behind-memod@2x.jpg"
                    },
                    mobile: {
                        x1: "behind-memod-mobile.jpg",
                        x2: "behind-memod-mobile@2x.jpg"
                    }
                }
            },
            sharingKnowledge: {
                title: "Memos: A new way of gaining and sharing knowledge",
                isExpanded: true,
                descriptionBullets: [
                    {
                        hideOnMobile: false,
                        bullet: "If something’s worth knowing, it’s worth remembering – and with as little effort as possible."
                    },
                    {
                        hideOnMobile: false,
                        bullet: "A Memo is a note that makes any subject instantly understandable through its simple, 10 bullet point structure."
                    },
                    {
                        hideOnMobile: false,
                        bullet: "It’s bite-sized inspiration that you can easily retain with no fluff and no wasted time."
                    },
                    {
                        hideOnMobile: false,
                        bullet: "Memo’d is designed to streamline your expertise so that your insights can be easily learned and shared by anyone."
                    }
                ],
                relativePathImages: {
                    web: {
                        x1: "sharing-knowledge.jpg",
                        x2: "sharing-knowledge@2x.jpg"
                    },
                    mobile: {
                        x1: "sharing-knowledge-mobile.jpg",
                        x2: "sharing-knowledge-mobile@2x.jpg"
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.about-us {
    padding-top: 150px;

    hr {
        border-top: 0;
        background: linear-gradient(90deg, rgba(0, 175, 149, 0) 0%, rgba(0, 175, 149, 0.4) 50%, rgba(0, 175, 149, 0) 100%);
        height: 2px;
        margin-top: 50px;
        margin-bottom: 90px;
    }

    .who-we-are {
        @media(min-width: $xl) {
            max-width: 75%;
            margin: 0 auto;
        }
        h1 {
            text-align: center;
            margin-bottom: 30px;

            @media(max-width: $md) {
                font-size: 24px;
                line-height: 30px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            margin-bottom: 65px;
            color: #B5B5B5;
        }

        .collaborator-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgba(44,44,46,0.9);
            border: 1px solid rgba(112, 112, 112, 0.2);
            padding: 25px;
            border-radius: 10px;
            margin-bottom: 45px;

            @media(max-width: $sm) {
                max-width: 235px;
                margin: 0 auto;
                margin-bottom: 45px;
            }

            figure {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                overflow: hidden;
                margin-bottom: 15px;

                img {
                    width: 100%;
                }
            }

            p {
                font-size: 17px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                color: #f2f2f2;
                margin-bottom: 15px;
            }

            a {
                padding: 2px 20px;
            }
        }

    }

    .feature {
        /deep/ h1 {
            color: white;
            background: transparent;
        }

        /deep/ .row {
            flex-direction: row-reverse;

        }
    }

    .memos-on-memod {
        margin-bottom: 150px;

        .row {
            .col-12 {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        h1 {
            text-align: center;
            margin-bottom: 30px;

            @media(max-width: $md) {
                font-size: 24px;
                line-height: 30px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            margin-bottom: 65px;
            color: #B5B5B5;
        }

        .memod-card {
            width: 260px;
            margin: 0 auto;
            margin-bottom: 25px;
        }
    }
}
</style>
